
class Security
{

	/**
	 * Constructor
	 */
	Security()
	{
		// determine user fingerprint
		if (window.requestIdleCallback)
		{
		    requestIdleCallback(function ()
		    {
		        Fingerprint2.get(function (components)
		        {
		        	var fingerprint = Fingerprint2.x64hash128(components.map(function (pair) { return pair.value }).join(), 31)
		        	save(fingerprint);
		        })
		    })
		}
		else
		{
		    setTimeout(function ()
		    {
		        Fingerprint2.get(function (components)
		        {
		        	var fingerprint = Fingerprint2.x64hash128(components.map(function (pair) { return pair.value }).join(), 31)
		        	save(fingerprint);
		        })
		    }, 500)
		}
	}

	/**
	 * Save fingerprint to db
	 */
	save(fingerprint)
	{
		$.ajax(
		{
			url: "/services/save-fingerprint.php",
			type: "POST",
			data: {
				"fingerprint":fingerprint
			},
			success: function(data)
			{
				// ignore
			}
		});
	}

	static validateFormInput(elm)
	{
		// ignore empty
		if (elm == null) return true;

		var valid = false;

		if(!elm.checkValidity())
		{
			$(elm).closest(".form-group").find(".input-status")
			.html("<i class='fas fa-exclamation-circle text-danger'></i>");

			$(elm).closest(".form-group").find("small")
			.html("Invalid details");
		}
		else
		{
			$(elm).closest(".form-group").find(".input-status")
			.html("<i class='fas fa-check-circle text-success'></i>");

			var original = $(elm).closest(".form-group").find("small")
			.data("info");

			$(elm).closest(".form-group").find("small")
			.html(original);

			valid = true;
		}

		return valid;
	}

	static cleanEmail(email)
	{
		var result = email.trim();

		if (result.length > 100)
		{
			result = "";
		}

		if (!result.match("^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,10}$"))
		{
			result = "";
		}

    	return result;
	}

	static cleanPassword(password)
	{
		var result = password.trim();

		if (result.length > 100)
		{
			result = "";
		}

		if (!result.match("^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$"))
		{
			result = "";
		}

		return result;
	}

	static cleanID(input)
	{
		var result = input.trim();

		if (result.length > 10)
		{
			result = 0;
		}

		if (!result.match("[0-9]{8,10}"))
		{
			result = 0;
		}

		if (result < 0)
		{
			result = 0;
		}

		return result;
	}

	static checkCSRFMethod(method)
	{
		return (/^(GET|HEAD|OPTIONS)$/.test(method));
	}

	static loadCSRF()
	{
		var obj = this;
		var CSRFtoken = $('meta[name="csrf-token"]').attr('content');

		$.ajaxSetup(
		{
			beforeSend: function(xhr, settings)
			{
				if (!obj.checkCSRFMethod(settings.type) && !this.crossDomain)
				{
					xhr.setRequestHeader('anti-csrf-token', CSRFtoken);
				}
			}
		});
	}

	static secureAJAX(url, data, type, success, error)
	{
		this.loadCSRF();

		$.ajax(
		{
			url: url,
			data: data,
			type: type,
			success: success,
			error: error
		});
	}

}
