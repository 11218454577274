
class Misc
{

	/**
	 * Date manipulation
	 *
	 * @param date
	 * @param days
	 *
	 * @returns
	 */
	static padDate(date, days)
	{
		var dateTime = new Date(date);
		return dateTime.setDate(dateTime.getDate() + days);
	}

	/**
	 * Copy to clipboard
	 *
	 * @param value
	 */
	static copy(value)
	{
		var $temp = $("<input>");
		$("body").append($temp);
		$temp.val(value).select();
		document.execCommand("copy");
		$temp.remove();
	}

	/**
	 * Return if currency amount is valid
	 *
	 * @param number value
	 *
	 * @returns boolean
	 */
	static validCurrency(value)
    {
		var valid = /^(?=.*[1-9])(\d{0,16}(\.(\d{0,8}))?)$|^(?=.*[1-9])(\.(\d{0,8})?)$/.test(value);

		if (value == 0)
		{
			valid = true;
		}

    	return valid;
    }

	/**
	 * Remove unwanted characters from numeric input
	 *
	 * @param number value
	 *
	 * @returns number
	 */
	static cleanNumber(value)
	{
		// convert to string
		value = "" + value;

		// max length
		var max = (value.indexOf(".") > -1) ? 21 : 12;
		var cleaned = (value.length >= max) ? value.substr(0, max) : value;

		// replace non numeric or period chars
		cleaned = cleaned.replace(/[^0-9\.]+/g, "");

		// format number
		if (cleaned.indexOf(".") > -1)
		{
			var parts = cleaned.split(".");

			var left = parts[0].match(/[0-9]{1,12}/);
			left = (left == null) ? 0 : left;

			var right = parts[1].match(/[0-9]{0,8}/);
			right = (right == null) ? 0 : right;

			cleaned = left + "." + right;
		}

		// strip 0's in front
		cleaned = cleaned.replace(/^0+(?!\.|$)/, "");

		// replace empty string with 0
		cleaned = (cleaned != "") ? cleaned : 0;

		return cleaned;
	}

	/**
	 * Format currency using set decimals
	 *
	 * @param number value
	 * @param number decimalPlaces
	 *
	 * @returns number
	 */
	static formatCurrency(value, decimalPlaces)
	{
		function reverse(text)
		{
			return text.split("").reverse().join("");
		}

		var rx = /(\d{3}(?!.*\.|$))/g;
		var separator = " ";

		decimalPlaces = decimalPlaces || 2;

		if (typeof value == 'string')
		{
			value = parseFloat(value);
		}

		return reverse(reverse(value.toFixed(decimalPlaces)).replace(rx, "$1" + separator));
	}

	/**
	 * Return if label is valid
	 *
	 * @param value
	 *
	 * @returns boolean
	 */
	static validLabel(value)
    {
		return /^[A-Za-z0-9 ]+$/.test(value);
    }

	/**
	 * open window
	 *
	 * @param url
	 */
	static openWindow(url)
	{
		window.open(url, '', 'menubar=no,toolbar=no,resizable=no,scrollbars=no,height=400,width=600');
	}

	/**
	 * Standard error output
	 *
	 * @param message
	 */
	static errorMessage(message)
	{
		Misc.message("Error", message, "error");
	}

	/**
	 * Show custom message
	 *
	 * @param title
	 * @param message
	 * @param type
	 * @param html
	 */
	static message(title, message, type, html = false)
	{
		// default type
		if (type == "")
		{
			type = "success";
		}

		// base object
		var data = {
			title: title,
			text: message,
			type: type,
			confirmButtonColor: "#6c757d"
		};

		// message type
		if (html)
		{
			data.html = message;
		}
		else
		{
			data.text = message;
		}

		swal.fire(data);
	}

	/**
	 * Dismiss alert message
	 */
	static dismissAlert()
	{
		$.ajax(
		{
			url: "/services/dismiss-alert.php",
			success: function(data)
			{
				// ignore
			},
			error: function()
			{
				// ignore
			}
		});
	}

	/**
	 * Return bootstrap viewport
	 */
	static viewportSize(size)
	{
		return window.getComputedStyle(document.body, ':before').content.replace(/"/g, '') == size;
	}

	/**
	 * Toggle theme
	 */
	static themeControl()
	{
		Misc.initTheme();

		$("#themeToggle").on("change", function()
		{
			Misc.resetTheme();

			// change charts
			tradingSetup(true);
		});
	}

	static initTheme()
	{
		var selected = localStorage.getItem("theme") !== null && localStorage.getItem("theme") === "dark";

		$("#themeToggle").attr("checked", selected);
		selected ? document.body.setAttribute("data-theme", "dark") : document.body.removeAttribute("data-theme");
	}

	static resetTheme()
	{
		if ($("#themeToggle").is(":checked"))
		{
			document.body.setAttribute("data-theme", "dark");
			localStorage.setItem("theme", "dark");
			document.cookie = "theme=dark; expires=Thu, 01 Jan 2090 00:00:00 UTC; path=/;";
		}
		else
		{
			document.body.removeAttribute("data-theme");
			localStorage.removeItem("theme");
			document.cookie = "theme=dark; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
		}
	}

	/**
	 * Cookie
	 */
	static getCookie(cname)
	{
		var name = cname + "=";
		var ca = document.cookie.split(';');

		for(var i = 0; i < ca.length; i++)
		{
			var c = ca[i];

			while (c.charAt(0) == ' ')
			{
				c = c.substring(1);
			}

			if (c.indexOf(name) == 0)
			{
				return c.substring(name.length, c.length);
			}
		}

		return "";
	}

	/**
	 * Math
	 */
	static relDiff(a, b)
	{
		return a-b === 0 ? 0 : 100 * Math.abs( ( a - b ) / b  );
	}

	/**
	 * Colors
	 */
	static rgb2hex(rgb)
	{
		rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
		return "#" + Misc.hex(rgb[1]) + Misc.hex(rgb[2]) + Misc.hex(rgb[3]);
	}

	static hex(x)
	{
		var hexDigits = new Array("0","1","2","3","4","5","6","7","8","9","a","b","c","d","e","f");
		return isNaN(x) ? "00" : hexDigits[(x - x % 16) / 16] + hexDigits[x % 16];
	}

}
